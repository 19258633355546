html, body {
  background-color: #f7fffa;
  color: #203C45; }

html, body {
  font-family: "Lato", sans-serif;
  font-size: 16px; }

html {
  letter-spacing: 0.25px;
  /*
  @include mq('p-320', 'max') {
    font-size: 6px;
  }
*/ }
  @media only screen and (max-width: 991px) {
    html {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    html {
      font-size: 12px; } }
  @media only screen and (max-width: 639px) {
    html {
      font-size: 10px; } }
  @media only screen and (max-width: 479px) {
    html {
      font-size: 8px; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0; }

h1 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1; }
  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 4rem; } }

h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25; }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 3rem; } }

h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25; }
  @media only screen and (max-width: 991px) {
    h3 {
      font-size: 2.5rem; } }

h4 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25; }

h5 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25; }

.rokkitt {
  font-family: "Rokkitt"; }

.lato {
  font-family: "Lato"; }

.btn {
  border: 2px solid #203C45;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  transition: 0.5s ease; }
  .btn:hover {
    box-shadow: inset 0 0 1rem 0 #0033E9;
    margin-left: -1px;
    margin-top: -1px; }
  .btn.pulsing {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -ms-animation-name: pulse;
    -o-animation-name: pulse;
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -ms-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out; }
    .btn.pulsing:hover {
      animation-name: initial;
      -webkit-animation-name: initial;
      -moz-animation-name: initial;
      -ms-animation-name: initial;
      -o-animation-name: initial; }

.btn, .link {
  text-decoration: none;
  color: initial; }

.rolling-link {
  cursor: pointer;
  display: block;
  font-size: 1.5rem;
  overflow: hidden;
  padding-right: 0.5rem;
  position: relative;
  text-decoration: none; }
  .rolling-link > div {
    white-space: nowrap; }
    .rolling-link > div span {
      display: inline-block;
      transform-style: preserve-3d;
      transition: transform 0.5s ease;
      transition-delay: calc(0.05s * var(--index));
      transform-origin: bottom; }
    .rolling-link > div:nth-child(1) {
      letter-spacing: 0; }
    .rolling-link > div:nth-child(2) {
      top: 0;
      font-family: "Roboto", sans-serif;
      font-style: italic;
      left: 0;
      letter-spacing: 0.5px;
      position: absolute; }
      .rolling-link > div:nth-child(2) span {
        transform: translateY(100%) rotateX(-90deg); }
  .rolling-link.speedy > div span {
    transition: transform 0.25s ease;
    transition-delay: calc(0.025s * var(--index)); }
  .rolling-link:not(.active):hover div:nth-child(1) span {
    transform: translateY(-100%) rotateX(-90deg); }
  .rolling-link:not(.active):hover div:nth-child(2) span {
    transform: translate(0) rotateX(0); }

@keyframes pulse {
  0% {
    box-shadow: 0 0rem 0rem 0rem #0033e9;
    transform: scale(1); }
  40% {
    box-shadow: 0 0rem 0rem 0rem #0033e9;
    transform: scale(1); }
  50% {
    transform: scale(1.02); }
  50% {
    box-shadow: 0 0rem 5rem 1rem rgba(0, 51, 233, 0);
    transform: scale(0.98); }
  60% {
    box-shadow: 0 0rem 0rem 0rem rgba(0, 51, 233, 0);
    transform: scale(1); } }

html, body {
  background-color: #f7fffa;
  color: #203C45; }

html, body {
  font-family: "Lato", sans-serif;
  font-size: 16px; }

html {
  letter-spacing: 0.25px;
  /*
  @include mq('p-320', 'max') {
    font-size: 6px;
  }
*/ }
  @media only screen and (max-width: 991px) {
    html {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    html {
      font-size: 12px; } }
  @media only screen and (max-width: 639px) {
    html {
      font-size: 10px; } }
  @media only screen and (max-width: 479px) {
    html {
      font-size: 8px; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0; }

h1 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1; }
  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 4rem; } }

h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25; }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 3rem; } }

h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25; }
  @media only screen and (max-width: 991px) {
    h3 {
      font-size: 2.5rem; } }

h4 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25; }

h5 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25; }

.rokkitt {
  font-family: "Rokkitt"; }

.lato {
  font-family: "Lato"; }

.section {
  box-sizing: border-box;
  min-width: 100vw;
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 1439px) {
    .section {
      height: initial;
      overflow: visible; } }
  .section__component {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 4rem 0;
    width: 100%; }
    @media only screen and (max-width: 1439px) {
      .section__component {
        padding: 2rem 4rem;
        position: relative; } }

.section--about {
  box-sizing: border-box;
  font-size: 1.75rem;
  gap: 2rem;
  line-height: 1.5;
  padding: 4rem 16rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .section--about::-webkit-scrollbar {
    display: none; }
  @media only screen and (max-width: 1679px) {
    .section--about {
      padding: 4rem 10rem; } }
  @media only screen and (max-width: 1439px) {
    .section--about {
      height: initial;
      padding: 4rem 2rem; } }
  .section--about .about__title {
    margin-bottom: 2rem; }
    @media only screen and (max-width: 1439px) {
      .section--about .about__title {
        display: none; } }
    .section--about .about__title--mobile {
      display: none;
      margin-bottom: 2rem; }
      @media only screen and (max-width: 1439px) {
        .section--about .about__title--mobile {
          display: block; } }
  .section--about .about__photo {
    float: left;
    padding: 0 2rem 2rem 0; }
    .section--about .about__photo__img {
      width: 100%; }
      @media only screen and (max-width: 1439px) {
        .section--about .about__photo__img {
          width: 100%; } }
  .section--about .about__intro1 {
    margin-bottom: 2rem; }
    @media only screen and (max-width: 1439px) {
      .section--about .about__intro1 {
        font-size: 1.75rem; } }
  .section--about .about__intro2 {
    margin-bottom: 2rem; }
    @media only screen and (max-width: 1439px) {
      .section--about .about__intro2 {
        font-size: 1.75rem; } }
  .section--about .about__intro3 {
    margin-bottom: 2rem; }
    @media only screen and (max-width: 1439px) {
      .section--about .about__intro3 {
        font-size: 1.75rem; } }
  .section--about .about__intro4 {
    margin-bottom: 1rem; }
    @media only screen and (max-width: 1439px) {
      .section--about .about__intro4 {
        font-size: 2rem; } }
  .section--about .about__contact {
    width: fit-content; }
    .section--about .about__contact #contact-me {
      color: #B95F00;
      font-size: 2rem;
      font-weight: 600;
      white-space: nowrap;
      text-decoration: none; }
  .section--about .about__jotform {
    position: relative;
    overflow: hidden; }
    .section--about .about__jotform iframe {
      margin-bottom: -5rem; }

.section--default {
  font-size: 8rem;
  line-height: 100%;
  text-align: center;
  color: #0033E9; }
  .section--default .tree-container {
    bottom: 0;
    height: 100%; }
    .section--default .tree-container__gif {
      opacity: 1;
      width: 100%; }
    .section--default .tree-container .redesign-text {
      color: #001d83;
      font-size: 6rem;
      line-height: 100%;
      position: absolute;
      text-align: center;
      top: 40%;
      transform: rotate(45deg);
      width: 100%; }

.section--home {
  align-items: center;
  color: black;
  display: grid;
  font-size: 8rem;
  grid-template: "hi hi hi" min-content "create tree ." 1fr "slides tree build" 1fr "slides tree ." 1fr "slides tree idea" 1fr "slides tree ." 1fr ". tree ." 1fr / 1fr auto 1fr;
  height: calc(100vh - 9rem);
  overflow-y: auto;
  padding-bottom: 0 !important;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .section--home::-webkit-scrollbar {
    display: none; }
  .section--home h3 {
    display: block;
    padding: 1rem; }
  .section--home .tree-container {
    align-items: end;
    align-self: end;
    background: radial-gradient(ellipse at 50% 110%, #48271C 10%, transparent 15%, transparent) 0px 0px;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center; }
    .section--home .tree-container__gif {
      font-size: 0rem;
      max-height: 100%;
      opacity: 1; }
      @media only screen and (max-width: 1024px) {
        .section--home .tree-container__gif {
          height: initial;
          max-width: 100%;
          max-height: 100%; } }

@media only screen and (max-width: 1439px) and (max-height: 1024px) {
  .section--home .home__slides {
    max-width: 28rem; } }
  @media only screen and (max-width: 1247px) {
    .section--home {
      box-shadow: 0px 0.25rem 0rem 0.5rem #B28F76;
      grid-template: "hi hi" min-content "create tree" 1fr "build tree" 1fr "slides tree" 1fr "idea tree" 1fr / 1fr 1fr; } }
  @media only screen and (max-width: 1247px) and (max-height: 1024px) {
    .section--home {
      grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr; }
      .section--home .home__slides {
        display: block;
        max-width: 20rem;
        text-decoration: none; } }

@media only screen and (max-width: 1024px) and (max-height: 640px) {
  .section--home .home__slides {
    max-width: 20rem; }
  .section--home .home__slide-container__demo-label {
    font-size: 0.75rem;
    height: 5.5rem;
    padding-top: 4.25rem;
    right: -3rem;
    top: -3rem;
    width: 5.5rem; } }
  @media only screen and (max-width: 991px) {
    .section--home {
      grid-template: "hi hi" min-content "create build" min-content "slides slides" min-content "idea tree" 1fr "idea tree" 1fr "idea tree" 1fr / 1fr 1fr;
      grid-gap: 2rem;
      height: calc(100vh - 9rem); }
      .section--home h3 {
        line-height: 1; }
      .section--home .home__slides {
        max-width: 30rem;
        /*      padding: 0 2rem 2rem; */ } }
  @media only screen and (max-width: 991px) and (max-height: 768px) {
    .section--home {
      grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr; }
      .section--home h3 {
        line-height: 1; }
      .section--home .home__slides {
        max-width: 16rem; } }
  @media only screen and (max-width: 767px) {
    .section--home .home__slides {
      max-width: 28rem; } }

@media only screen and (max-width: 767px) and (max-height: 640px) {
  .section--home {
    grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr;
    grid-gap: 1rem; }
    .section--home .home__slides {
      max-width: 22rem; }
    .section--home .home__slide-container__demo-label {
      font-size: 0.75rem;
      height: 5.5rem;
      padding-top: 4.25rem;
      right: -3rem;
      top: -3rem;
      width: 5.5rem; } }

@media only screen and (max-width: 767px) and (max-height: 480px) {
  .section--home {
    grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr; }
    .section--home .home__slides {
      max-width: 18rem; }
    .section--home .home__slide-container__demo-label {
      font-size: 0.75rem;
      height: 5.5rem;
      padding-top: 4.25rem;
      right: -3rem;
      top: -3rem;
      width: 5.5rem; } }

@media only screen and (max-width: 767px) and (max-height: 320px) {
  .section--home {
    padding: 1rem 4rem;
    grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr; }
    .section--home .home__slides {
      max-width: 18rem; }
    .section--home .home__slide-container__demo-label {
      font-size: 0.75rem;
      height: 5.5rem;
      padding-top: 4.25rem;
      right: -3rem;
      top: -3rem;
      width: 5.5rem; } }
  @media only screen and (max-width: 639px) {
    .section--home {
      grid-template: "hi hi" min-content "create build" min-content "idea slides" 1fr "tree tree" 1fr "tree tree" 1fr / 1fr 1fr;
      grid-gap: 1rem; }
      .section--home .home__slides {
        max-width: 28rem; } }
  @media only screen and (max-width: 639px) and (max-height: 480px) {
    .section--home {
      grid-template: "hi hi hi" min-content "create tree build" 1fr "slides tree idea" 1fr "slides tree ." 1fr / 1fr 1fr 1fr; }
      .section--home .home__slides {
        max-width: 28rem; } }
  @media only screen and (max-width: 479px) {
    .section--home {
      grid-template: "hi" min-content "create" min-content "build" min-content "slides" min-content "idea" min-content "tree" minmax(0, 1fr)/1fr; }
      .section--home .home__slides {
        max-width: 36rem; } }
  @media only screen and (max-width: 319px) {
    .section--home .home__slides {
      max-width: 36rem; } }

.home__hi {
  grid-area: hi; }
  @media only screen and (max-width: 991px) {
    .home__hi > * {
      margin-bottom: 1rem; } }

.home__create {
  grid-area: create;
  white-space: nowrap; }
  @media only screen and (max-width: 991px) {
    .home__create {
      white-space: initial; } }

.home__build {
  grid-area: build;
  white-space: nowrap; }
  @media only screen and (max-width: 991px) {
    .home__build {
      white-space: initial; } }

.home__idea {
  grid-area: idea; }
  .home__idea .rolling-link {
    font-size: unset; }
    .home__idea .rolling-link > div {
      white-space: normal; }

.home__slides {
  justify-self: center;
  grid-area: slides;
  max-width: 28rem; }

.home__tree {
  grid-area: tree; }
  .home__tree img {
    /*      width: 100%; */
    height: auto;
    aspect-ratio: 9/12; }

.home__slide-container {
  border: 1px solid black;
  cursor: pointer;
  display: block;
  overflow: hidden; }
  .home__slide-container__img {
    width: 100%; }
  .home__slide-container__demo-label {
    background-color: orange;
    font-size: 1rem;
    font-weight: 600;
    height: 8rem;
    padding-top: 6rem;
    position: absolute;
    right: -4rem;
    transform: rotate(45deg);
    top: -4rem;
    width: 8rem;
    z-index: 1; }

html, body {
  background-color: #f7fffa;
  color: #203C45; }

.section--portfolio {
  overflow-y: auto;
  padding: 4rem 16rem;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .section--portfolio::-webkit-scrollbar {
    display: none; }
  @media only screen and (max-width: 1679px) {
    .section--portfolio {
      padding: 4rem 10rem; } }
  @media only screen and (max-width: 1439px) {
    .section--portfolio {
      padding: 4rem 2rem; } }
  .section--portfolio .portfolio__title {
    line-height: 100%;
    margin-bottom: 2rem; }
  .section--portfolio .portfolio__work-item {
    display: grid;
    grid-template: ". title" min-content "img description" 1fr / 30% 1fr;
    grid-gap: 1rem 2rem;
    margin-bottom: 4rem; }
    .section--portfolio .portfolio__work-item:nth-child(even) {
      grid-template: "title ." min-content "description img" 1fr / 1fr 30%; }
      .section--portfolio .portfolio__work-item:nth-child(even) .work-item__title {
        text-align: right; }
    .section--portfolio .portfolio__work-item.active .work-item__img__scroller {
      opacity: 1;
      transition: opacity 1s linear, visibility 0s linear 0s;
      visibility: visible; }
    @media only screen and (max-width: 639px) {
      .section--portfolio .portfolio__work-item:not(.portfolio__work-item--scroller) {
        display: block; }
        .section--portfolio .portfolio__work-item:not(.portfolio__work-item--scroller) .work-item__img {
          margin: 2rem 0 2rem auto; } }
    @media only screen and (max-width: 639px) {
      .section--portfolio .portfolio__work-item:not(.portfolio__work-item--scroller):nth-child(odd) .work-item__img {
        margin: 2rem 0; } }
    .section--portfolio .portfolio__work-item a {
      text-decoration: none;
      transition: text-shadow 0.1s ease; }
      .section--portfolio .portfolio__work-item a:hover {
        cursor: pointer;
        text-shadow: 2px 2px 2px #203C45; }
  .section--portfolio .portfolio__cta {
    text-align: center; }
    .section--portfolio .portfolio__cta .btn {
      background-color: #D5F4FF; }
  .section--portfolio .work-item__img {
    display: block;
    grid-area: img; }
    @media only screen and (max-width: 639px) {
      .section--portfolio .work-item__img {
        display: block;
        margin: 1rem auto;
        max-width: 80%; } }
    .section--portfolio .work-item__img img {
      border: 1px solid black;
      width: 100%; }
    .section--portfolio .work-item__img__scroller {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity 1s linear, visibility 0s linear 1s;
      visibility: hidden;
      z-index: 2; }
  .section--portfolio .work-item__description {
    font-size: 1.5rem;
    grid-area: description;
    line-height: 1.25; }
  .section--portfolio .work-item__title {
    grid-area: title;
    line-height: 1;
    white-space: nowrap; }
    .section--portfolio .work-item__title > * {
      display: inline-block;
      width: fit-content; }
  .section--portfolio a.work-item__img img:hover {
    box-shadow: 2px 2px 2px #203C45; }

.section--services {
  align-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  padding: 2rem 16rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .section--services::-webkit-scrollbar {
    display: none; }
  @media only screen and (max-width: 1679px) {
    .section--services {
      padding: 2rem 10rem; } }
  @media only screen and (max-width: 1439px) {
    .section--services {
      padding: 2rem 2rem; } }
  .section--services .services__title {
    font-family: "Roboto", sans-serif;
    text-align: left;
    white-space: nowrap; }
    .section--services .services__title__head {
      margin-bottom: 1.5rem; }
    @media only screen and (max-width: 1439px) {
      .section--services .services__title {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
  .section--services .services__card {
    background-color: #EFFBFF;
    border: 1px solid black;
    border-radius: 1rem;
    box-sizing: border-box;
    display: grid;
    gap: 1rem;
    grid-template: "title title" min-content "img description" 1fr / 12rem 1fr;
    padding: 2rem; }
    @media only screen and (max-width: 1439px) {
      .section--services .services__card {
        border-bottom: 1px solid black;
        gap: 4rem;
        grid-template: "img title" 1fr "description description" min-content / 1fr 3fr;
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    .section--services .services__card__title {
      grid-area: title; }
      @media only screen and (max-width: 1439px) {
        .section--services .services__card__title {
          align-items: center;
          display: flex; } }
    .section--services .services__card__img {
      grid-area: img; }
      .section--services .services__card__img img {
        width: 100%; }
    .section--services .services__card__description {
      font-size: 1.75rem;
      line-height: 1.5;
      grid-area: description; }
      @media only screen and (max-width: 1439px) {
        .section--services .services__card__description {
          font-size: 2rem; } }
  .section--services .services__cta {
    margin: 4rem;
    text-align: center; }
    .section--services .services__cta .btn {
      background-color: #D5F4FF; }

.landing-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #D5F4FF;
  overflow: hidden;
  z-index: 99;
  transition: height 1000ms cubic-bezier(0.45, 0.1, 0.9, 0.2); }
  .landing-container--active {
    height: 0%; }

.drop {
  position: relative;
  width: 2rem;
  height: 2rem;
  top: 2rem;
  margin: 0 auto;
  opacity: 1;
  background: radial-gradient(ellipse 20% 20% at 65% 70%, #FFFFFFAA 0%, white 20%, rgba(174, 214, 241, 0.8) 95%, #AED6F1 100%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation-name: drip;
  -webkit-animation-name: drip;
  -moz-animation-name: drip;
  -ms-animation-name: drip;
  -o-animation-name: drip;
  animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -webkit-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -moz-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -ms-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -o-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1; }

.drop:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  opacity: 1;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 2.1rem solid #AED6F1;
  top: -1.5rem;
  animation-name: drip-2;
  -webkit-animation-name: drip-2;
  -moz-animation-name: drip-2;
  -ms-animation-name: drip-2;
  -o-animation-name: drip-2;
  animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -webkit-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -moz-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -ms-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  -o-animation-timing-function: "cubic-bezier(0.670, 0.110, 1, 0.085)";
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1; }

.wave {
  position: relative;
  opacity: 0;
  top: calc(90% + 1.75rem);
  left: calc(50% + 0rem);
  width: 0rem;
  height: 0rem;
  border: #FFF 7px solid;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation-name: ripple;
  -webkit-animation-name: ripple;
  -moz-animation-name: ripple;
  -ms-animation-name: ripple;
  -o-animation-name: ripple;
  animation-delay: 1.75s;
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  -ms-animation-delay: 1.75s;
  -o-animation-delay: 1.75s;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1; }

.wave:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: calc(90% - 0.25rem);
  left: calc(50% - 0.5rem);
  width: 0rem;
  height: 0rem;
  border: #FFF 5px solid;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation-name: ripple-2;
  -webkit-animation-name: ripple-2;
  -moz-animation-name: ripple-2;
  -ms-animation-name: ripple-2;
  -o-animation-name: ripple-2;
  animation-delay: 1.75s;
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  -ms-animation-delay: 1.75s;
  -o-animation-delay: 1.75s;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1; }

@keyframes ripple {
  from {
    top: calc(90% + 1.75rem);
    left: calc(50% + 0rem);
    width: 0rem;
    height: 0rem;
    opacity: 1; }
  to {
    left: calc(50% - 20rem);
    top: calc(90% - 10rem);
    width: 40rem;
    height: 20rem;
    border-width: 1rem;
    opacity: 0; } }

@keyframes ripple-2 {
  0% {
    top: calc(90% - 0.25rem);
    left: calc(50% - 0.5rem);
    width: 0rem;
    height: 0rem;
    opacity: 1; }
  100% {
    left: calc(50% - 5rem);
    top: calc(90% - 2.5rem);
    width: 10rem;
    height: 5rem;
    border-width: 1rem; } }

@keyframes drip {
  from {
    top: -4rem;
    width: 2rem;
    height: 2rem;
    opacity: 1; }
  90% {
    height: 2rem;
    opacity: 1; }
  95% {
    height: 1rem;
    opacity: 0.5;
    width: 2.5rem; }
  to {
    height: 0rem;
    top: calc(90% + 2rem);
    width: 5rem;
    opacity: 0; } }

@keyframes drip-2 {
  from {
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 2.1rem solid #AED6F1;
    top: -1.5rem;
    left: 0; }
  90% {
    opacity: 1; }
  95% {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 0.25rem solid #AED6F1;
    opacity: 0.5;
    top: 0.25rem;
    left: 0; }
  to {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 0rem solid transparent;
    left: -0.5rem;
    top: 0.25rem;
    opacity: 0; } }

.ns-main .floating-banner {
  background-color: #008505;
  color: #EFFBFF;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  left: 0;
  opacity: 0;
  padding: 0.75rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity 1s linear, z-index 0s linear 1s;
  z-index: 0; }
  .ns-main .floating-banner--active {
    opacity: 1;
    z-index: 101;
    transition: opacity 1s linear, z-index 0s linear 0s; }

.ns-main .header {
  align-items: center;
  box-shadow: 0px 1px 2px rgba(32, 60, 69, 0.25);
  background-color: #D5F4FF;
  display: flex;
  font-size: 1rem;
  height: 5rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99; }
  @media only screen and (max-width: 639px) {
    .ns-main .header {
      padding: 1rem; } }
  .ns-main .header__logo {
    font-size: 2.5rem;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    text-decoration: none;
    white-space: nowrap; }
    @media only screen and (max-width: 639px) {
      .ns-main .header__logo {
        font-size: 1.75rem; } }
    .ns-main .header__logo__net {
      color: #0033E9; }
    .ns-main .header__logo__scaping {
      color: #008505; }
    .ns-main .header__logo__img, .ns-main .header__logo__text {
      display: inline-block;
      vertical-align: middle; }
    .ns-main .header__logo__img {
      width: 2.5rem; }
      .ns-main .header__logo__img img {
        max-width: 100%; }
    .ns-main .header__logo__text {
      font-size: 2.5rem;
      font-family: Roboto, sans-serif; }
  @media only screen and (max-width: 1439px) {
    .ns-main .header__availability {
      display: none; } }

.ns-main .footer {
  align-items: center;
  background-color: #B28F76;
  color: black;
  display: flex;
  font-size: 1rem;
  min-height: 4rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100; }
  .ns-main .footer__overlay {
    background: radial-gradient(40% 200% at bottom, transparent 0%, transparent 50%, #D5F4FF 60%, #D5F4FF 100%);
    animation-name: soil;
    -webkit-animation-name: soil;
    -moz-animation-name: soil;
    -ms-animation-name: soil;
    -o-animation-name: soil;
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -ms-animation-duration: 4s;
    -o-animation-duration: 4s;
    position: absolute;
    z-index: 1; }
  .ns-main .footer__copyright {
    flex: 1;
    white-space: nowrap; }
  .ns-main .footer__social {
    align-items: end;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    justify-content: end; }
    .ns-main .footer__social .social-logo {
      width: 2rem;
      margin: 0 5px; }
      @media only screen and (max-width: 991px) {
        .ns-main .footer__social .social-logo {
          width: 1.5rem; } }
    .ns-main .footer__social__logos {
      margin-bottom: 0.5rem; }
    @media only screen and (max-width: 991px) {
      .ns-main .footer__social {
        flex-direction: row; } }
    @media only screen and (max-width: 479px) {
      .ns-main .footer__social {
        flex-direction: column; } }
  .ns-main .footer .ns-nav__link {
    font-size: 1rem; }
    .ns-main .footer .ns-nav__link.active {
      font-size: 1.2rem; }
  .ns-main .footer__links a {
    display: block; }

.ns-main .offering__icons {
  padding: 0.25rem 0;
  text-align: center; }
  .ns-main .offering__icons > div {
    display: inline-block; }
    .ns-main .offering__icons > div .svg {
      display: inline-block;
      margin-right: 1rem;
      width: 2.5rem; }
      .ns-main .offering__icons > div .svg--size-up {
        width: 3rem; }
      @media only screen and (max-width: 991px) {
        .ns-main .offering__icons > div .svg {
          width: 2rem; } }

.ns-main .ns-nav {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-around;
  width: 100%; }
  .ns-main .ns-nav__link.active {
    font-size: 1.6rem;
    font-weight: 800; }
  @media only screen and (max-width: 639px) {
    .ns-main .ns-nav__link--home {
      display: none; } }

.ns-main .slideshow {
  bottom: 4rem;
  display: flex;
  height: calc(100vh - 9rem);
  overflow-x: hidden;
  top: 5rem; }
  @media only screen and (max-width: 1439px) {
    .ns-main .slideshow {
      display: block;
      margin-top: 5rem;
      position: sticky; } }

.ns-main .page {
  font-size: 6rem; }

* {
  position: relative;
  box-sizing: border-box; }

.limit-width {
  max-width: 1280px; }

html, body {
  overscroll-behavior-x: none;
  margin: 0; }

@keyframes soil {
  0% {
    bottom: -200%;
    left: 0%;
    right: 0%;
    top: 0%; }
  40% {
    bottom: -200%;
    left: 0%;
    right: 0%;
    top: 0%; }
  80% {
    bottom: -2000%;
    left: -40%;
    right: -40%;
    top: -40%; }
  90% {
    bottom: -2000%;
    left: -200%;
    right: -200%;
    top: -200%; }
  100% {
    bottom: -2000%;
    left: -200%;
    right: -200%;
    top: -200%; } }
